<template>
  <div class="fill-height container--fluid" style="width: 100%">
    <div class="container--fluid">
      <v-toolbar tile flat style="max-height: 30px">
        <v-btn icon v-on:click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title v-on:click="goBack" style="cursor: pointer">Go back</v-toolbar-title>
      </v-toolbar>
    </div>
    <v-container fluid class="mx-0 justify-start">
      <v-row align="start" justify="start">
        <v-col cols="12" md="3">
          <v-sheet class="mt-5">
            <v-card flat hover elevation="0" class="rounded-lg">
              <v-img v-if="profile.pictures[0] != null" :src="
                  profile.pictures != null
                    ? profile.pictures[0].url
                    : require('@/assets/images/no-image.jpg')
                " :lazy-src="require('@/assets/images/no-image.jpg')" position="top" contain height="300">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-title>
                <span class="indigo--text text-truncate">
                  {{
                  profile.name_english ? profile.name_english : profile.name
                  }}
                </span>
              </v-card-title>
              <v-card-text class="indigo--text">
                {{
                profile.description_english
                ? profile.description_english
                : profile.description
                }}
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="9">
          <v-container fluid class="mx-0 justify-start">
            <v-row justify="start" align="start" dense>
              <v-col cols="12">
                <h1 class="text-h5 font-weight-regular headline text-uppercase">
                  Specialists
                </h1>
              </v-col>
              <v-col cols="12">
                <div class="boxdivider"></div>
              </v-col>
              <v-col cols="12">
                <v-container fluid class="fill-height justify-start px-0">
                  <v-row justify="start" align="start">
                    <v-col v-for="(item, index) in profile.doctors" :key="index" class="d-flex child-flex" col="12"
                      md="4">
                      <v-card v-if="item.pictures !== undefined" outlined elevation="10" class="rounded-lg">
                        <img style="object-fit: cover; height: 300px; width: 100%; object-position: top"
                            :src="item.pictures !== undefined ? item.pictures[0].url : require('@/assets/images/no-image.jpg')"
                        />
                        <img v-if="item.premium===1" src="@/assets/images/icons/premium.png"
                             alt="sello"
                             class="superior-derecha"/>
                        <img v-else src="@/assets/images/icons/member.png"
                             alt="sello"
                             class="superior-derecha"/>
                        <img v-show="item.excellence===1" src="@/assets/images/icons/escudo_coepris.f8331f99.png" alt="sello1"
                             class="superior-derecha-segunda"/>
                        <img v-show="item.repsabi===1" src="@/assets/images/icons/repssabi.png" alt="sello1"
                             class="superior-derecha-tercera"/>
                        <v-btn v-model="selectedId" plain text elevation="0" class="mx-0 mt-1"
                          @click="showProfile(item.id)">
                          <span class="pb-0 indigo--text text-subtitle-2 text-truncate" v-text="item.name"></span>
                        </v-btn>

                        <v-card-actions class="mt-1 mb-1 justify-center">
                          <v-btn color="blue" icon :href="item.facebook_profile" target="_blank">
                            <v-icon size="24px" color="primary">mdi-facebook</v-icon>
                          </v-btn>
                          <v-btn icon color="#D93F8FFF" :href="item.instagram_profile" target="_blank">
                            <v-icon size="24px">mdi-instagram</v-icon>
                          </v-btn>
                          <v-btn icon :href="item.twitter_profile" target="_blank">
                            <i class="bi bi-twitter-x" style="font-size: 20px;"></i>
                          </v-btn>
                          <v-btn color="red" icon :href="item.youtube_video" target="_blank">
                            <v-icon size="24px">mdi-youtube</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="12">
                <v-row justify="center" class="ma-3" v-if="profile.doctors.length === 0">
                  <div>No hay Especialistas en el cat&aacute;logos...</div>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <footer-profile-component />
  </div>
</template>

<script>
import specialtiesService from "@/providers/SpecialtiesService";
import FooterProfileComponent from "@/layouts/app-layout/footer/FooterProfileComponent";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "SpecialtyProfileComponent",
  components: { FooterProfileComponent },
  data: () => ({
    selection: -1,
    selectedId: 0,
    model: null,
    profile: {
      id: 0,
      name: null,
      name_english: null,
      description: null,
      description_english: null,
      pictures: [],
      doctors: [],
    },
  }),
  methods: {
    goBack() {
      this.$router.push("/specialties-services");
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    isFechaVencida(fecha) {
      const fechaVencimiento = fecha.split("T")[0]; // Obtiene solo la parte de la fecha
      const fechaActual = new Date().toISOString().split("T")[0]; // Formatea la fecha actual en el mismo formato
      // Compara las fechas
      return fechaVencimiento > fechaActual;
    },
    getProfile(idSpecialty) {
      let record = null;
      specialtiesService.getRecord(idSpecialty).then((fetch_data) => {
        record = fetch_data.value;

        this.profile.id = record.id;
        this.profile.name = record.name;
        this.profile.name_english = record.name_english;
        this.profile.description = record.description;
        this.profile.description_english = record.description_english;
        this.profile.pictures = record.pictures;

        var datadoctors = [];
        var valor = record.doctors;
        for (var i = 0; i < valor.length; i++) {
          if (valor[i].coepris_qr && valor[i].vencimiento_fecha){
            if (this.isFechaVencida(valor[i].vencimiento_fecha)){
              datadoctors.push(valor[i]);
            }
          }
        }
        this.profile.doctors = datadoctors;

        changeInformationSEO(record.keywords, record.description_english);
        this.toTop();
      });
    },
    showProfile(idDoctor) {
      this.selectedId = idDoctor;
      this.$router.push({
        name: "DoctorProfile",
        params: {
          id: idDoctor,
        },
      });
    },
  },
  mounted() {
    this.getProfile(this.$route.params.id);
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
.boxdivider {
  background-color: transparent;
  width: 100%;
  height: 0px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}
.superior-derecha {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 80px;
  height: 80px;
}
.superior-derecha-segunda {
  position: absolute;
  top: 60px;
  right: 3px;
  width: 95px;
  height: 95px;
}
.superior-derecha-tercera {
  position: absolute;
  top: 160px;
  right: 12px;
  width: 70px;
  height: 70px;
}
@media(max-width: 767px) {
  .superior-derecha {
    position: absolute;
    top: -13px;
    right: -13px;
    width: 40px;
    height: 40px;
  }

  .superior-derecha-segunda {
    position: absolute;
    top: 30px;
    right: 3px;
    width: 50px;
    height: 50px;
  }
  .superior-derecha-tercera {
    position: absolute;
    top: 90px;
    right: 7px;
    width: 37px;
    height: 37px;
  }
}
</style>
